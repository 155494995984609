import Section from '../Section';
import Video from './Video';
import styles from './CaseStudy.module.scss';

import play from './play.svg';

const CaseStudy = () => (
  <Section
    id="CaseStudy"
    className={styles.CaseStudy}
  >
    <div className="container">
      <div className="row">
        <div className="col-12 text-center mb-4">
          <h1>Case Study</h1>
          <p>Continuous Online User Authentication in Banking</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 mb-4 mb-lg-0">
          <div className={styles.Box}>
            <div>
              <span>-85%</span>
              <p>Cost saving on OTPs via SMS</p>
            </div>
            <div>
              <span>97%</span>
              <p>Fraud detection accuracy</p>
            </div>
            <div>
              <span>100K+</span>
              <p>Authenticated user sessions daily</p>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <Video />
        </div>
        <div className="col-12 text-center mt-5 pt-5 pt-lg-0">
          <a href="/case-study" className="btn">
            Learn more
            {' '}
            <img src={play} />
          </a>
        </div>
      </div>
    </div>
  </Section>
);

export default CaseStudy;
