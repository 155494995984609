import { Link } from 'react-scroll';
import Section from '../Section';
import styles from './ZeroTrust.module.scss';

import play from './play.svg';

const ZeroTrust = () => (
  <Section
    id="ZeroTrust"
    className={styles.ZeroTrust}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center mb-3">
          <h1>
            Combine the layers to fit your goals
          </h1>
        </div>
        <div className="col-lg-12 text-center">
          <div className={styles.TableWrapper}>
            <div className={styles.Table}>
              <div className={styles.TableHead}>
                <div>
                  <h3>Solution Layer</h3>
                </div>
                <div>
                  <h3>Analyzed Data</h3>
                </div>
                <div>
                  <h3>Time To Value</h3>
                </div>
              </div>
              <div className={styles.TableContent}>
                <div className={styles.TableRow}>
                  <div>
                    <h4>CAPTCHA & Bot Detection</h4>
                    <p>
                      Frictionless detection of bots and scraping on your
                      website
                    </p>
                  </div>
                  <div>
                    <p>Human vs Bot Interactions</p>
                  </div>
                  <div>
                    <p>Immediate</p>
                    <a
                      href="https://graboxy.com/solutions/captcha-bot-detection"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div className={styles.TableRow}>
                  <div>
                    <h4>Remote Desktop Detection</h4>
                    <p>
                      Stop tech support, AnyDesk or other remote access scams
                      within 5 seconds
                    </p>
                  </div>
                  <div>
                    <p>Pre-defined Behavior Patterns</p>
                  </div>
                  <div>
                    <p>Immediate</p>
                    <a
                      href="https://graboxy.com/solutions/remote-desktop-detection"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.TableContent}>
                <div className={styles.TableRow}>
                  <div>
                    <h4>Device Fingerprinting</h4>
                    <p>
                      Frictionless security layer to approve authorized devices
                    </p>
                  </div>
                  <div>
                    <p>Unique Device Profiles</p>
                  </div>
                  <div>
                    <p>Immediate<br />after first session</p>
                    <a
                      href="https://graboxy.com/solutions/device-fingerprinting"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div className={styles.TableRow}>
                  <div>
                    <h4>Silent 2FA</h4>
                    <p>
                      Approve logins and transactions invisibly and prevent
                      website spoofing or mirror phishing fraud
                    </p>
                  </div>
                  <div>
                    <p>Enrolled Biometric User Profiles</p>
                  </div>
                  <div>
                    <p>Few weeks</p>
                    <a
                      href="https://graboxy.com/solutions/multi-factor-authentication"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div className={styles.TableRow}>
                  <div>
                    <h4>Continuous Authentication</h4>
                    <p>
                      Prevent account takeovers on desktop, web & mobile.
                    </p>
                    <ul>
                      <li>
                        Workforce authentication and privileged access
                        management
                      </li>
                      <li>
                        Prevent account and device sharing
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>Enrolled Biometric User Profiles</p>
                  </div>
                  <div>
                    <p>Few weeks</p>
                    <a
                      href="https://graboxy.com/solutions/continuous-authentication"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div className={styles.Badge}>
                  <p>Zero Trust</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link
          to="Contact"
          className="btn"
          spy
          smooth
        >
          Book a Demo
          {' '}
          <img src={play} />
        </Link>
      </div>
    </div>
  </Section>
);

export default ZeroTrust;
