import styles from './Video.module.scss';

const Video = () => (
  <iframe
    className={styles.Video}
    src="https://player.vimeo.com/video/757605026?h=943777d9ab"
    frameBorder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
  />
);

export default Video;
