import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { ContainerSpinner } from '../../../app/components/Spinner';
import Section from '../../../app/components/Section';
import usePosts from '../../hooks/usePosts';
import ErrorMessage from '../ErrorMessage';
import PostCard from '../PostCard';
import Posts from '../Posts';
import styles from './BlogSection.module.scss';
import './Slider.scss';

import play from './play.svg';

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Blog = () => {
  const { isLoading, posts, error } = usePosts({ limit: 3 });

  const renderedPosts = posts.map((post) => (
    <PostCard key={post.id} post={post} className="px-3" />
  ));

  return (
    <Section id="Blog" title="Our blog" className={styles.BlogSection}>
      <div className="Slider">
        <div className="container">
          {(!isLoading && error) && <ErrorMessage content={error.toString()} />}

          {isLoading
            ? <ContainerSpinner />
            : (
              <div className="row">
                <Slider {...SETTINGS} className="h-100">
                  {renderedPosts}
                </Slider>
              </div>
            )
          }

          {isLoading || (
            <div className="row text-center mt-3">
              <div className="col-12">
                <Link to="/blog" className="btn" target="_blank">
                  More Blog Articles
                  {' '}
                  <img src={play} />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default Blog;
