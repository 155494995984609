import { Link } from 'react-scroll';
import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import Steps from '../components/Steps';
import styles from './ContinuousAuthentication.module.scss';

import timer from '../components/gifs/timer.gif';
import ux from '../components/gifs/ux.gif';
import cloud from '../components/gifs/cloud.gif';

import chart from '../components/icons/chart36.svg';
import coins from '../components/icons/coins12.svg';
import graph from '../components/icons/graph230.svg';
import cybercriminal from '../components/icons/cybercriminal.svg';

const ContinuousAuthentication = () => {
  return (
    <AppLayout>
      <div className={styles.ContinuousAuthentication}>
        <div className={styles.GradientBg}>
          <Section
            title="Continuous Authentication"
            className={styles.Hero}
          >
            <div className="container">
              <div className="row mt-lg-3 mb-lg-5">
                <div className="col-12 text-center">
                  <iframe
                    width="720"
                    height="365"
                    src="https://player.vimeo.com/video/757605026?h=943777d9ab"
                    frameborder="0"
                    allow="autoplay;"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className="row mt-3 mb-5">
                <div className="col-12 text-center">
                  <h3>
                    Prevent account takeover frauds on desktop, web, and mobile.
                    <br />
                    Ensure Zero Trust workforce authentication and privileged
                    access management.
                    <br />
                    Prevent unauthorized account and device sharing.
                  </h3>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={cloud} width={100} />
                    </div>
                    <h4>
                      AI-Driven Scam Detection
                    </h4>
                    <p>
                      Advanced AI analyzes user behavior and identifies
                      potential scams
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={ux} width={100} />
                    </div>
                    <h4>
                      Frictionless UX
                    </h4>
                    <p>
                      Enhances security
                      <br />
                      without compromising the user experience
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={timer} width={100} />
                    </div>
                    <h4>
                      Zero Trust Support
                    </h4>
                    <p>
                      Helps to reach your Zero Trust goals by continuously
                      verifying user identities
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-12 py-3 text-center">
                  <Link to="Contact" className="btn" spy smooth>
                    Book a Demo
                  </Link>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 pt-3 text-center">
                  <h4>
                    Graboxy Continuous Authentication is part of the Graboxy AI
                    Cybersecurity Platform.
                  </h4>
                  <h4>
                    Learn more about the other features such as
                    {' '}
                    <br />
                    <a
                      href="/solutions/remote-desktop-detection"
                      className="mainlink"
                    >
                      Remote Desktop Detection
                    </a>
                    ,
                    {' '}
                    <a
                      href="/solutions/multi-factor-authentication"
                      className="mainlink"
                    >
                      Silent 2FA
                    </a>
                    ,
                    {' '}
                    <a
                      href="/solutions/device-fingerprinting"
                      className="mainlink"
                    >
                      Device Fingerprinting
                    </a>
                    , and
                    {' '}
                    <a
                      href="/solutions/captcha-bot-detection"
                      className="mainlink"
                    >
                      CAPTCHA & Bot Detection.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <div className={styles.Awards}>
          <Awards />
        </div>
        <div className={styles.GradientBg}>
          <Section
            title="How Graboxy Defends Against Identity Fraud and Account
                    Takeover Attacks"
            className={styles.Defend}
          >
            <div className="container mt-4">
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    Our AI continuously monitors users’ cursor movement and/or
                    mobile interaction and reports suspicious behavior.
                  </p>
                  <br />
                  <p>
                    Graboxy builds and evaluates against biometric user profiles
                    based on the uniqueness of cursor movement and/or mobile
                    interaction patterns. Special care is taken to recognize the
                    user and not only the device.
                  </p>
                  <br />
                  <p>
                    If the real-time cursor movement and/or mobile interaction
                    analysis shows a divergence from the user’s biometric
                    profile, Graboxy flags the fraudulent user accessing the
                    account or the device.
                  </p>
                  <br />
                  <p>
                    Graboxy Identity Score can also be combined with other
                    authentication models to protect user and workforce
                    accounts.
                  </p>
                </div>
                <div className="col-lg-6 d-flex">
                  <img className="w-100" src={cybercriminal} />
                </div>
              </div>
            </div>
          </Section>
        </div>
        <Section
          title="Case Study"
          className={styles.CaseStudy}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div>
                  <h3 className="mb-4">
                    Continuous Online User Authentication in Banking
                  </h3>
                  <p className="mb-4">
                    Goal: Decrease the number of fraudulent online banking
                    transactions and reduce the cost of transaction
                    authorization
                  </p>
                  <div className={styles.Results}>
                    <div className="d-md-flex justify-content-center my-5">
                      <div className={styles.ResultItem}>
                        <p>
                          Cost saving on
                          <br />
                          OTPs via SMS:
                        </p>
                        <h3>-85%</h3>
                      </div>
                      <div className={styles.ResultItem}>
                        <p>Fraud detection accuracy*:</p>
                        <h3>97%</h3>
                        <span>
                          * Where sufficient training data was available.
                        </span>
                      </div>
                      <div className={styles.ResultItem}>
                        <p>Authenticated user sessions daily:</p>
                        <h3>100K+</h3>
                      </div>
                    </div>
                  </div>
                  <a href="/case-study" className="btn">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          title="How It Works"
          className={styles.HowItWorks}
        >
          <div className="container">
            <Steps />
          </div>
        </Section>
        <Section
          title="Did You Know?"
          className={styles.DidYouKnow}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={coins} className="mb-4" height={75} />
                  <p>
                    The average loss of an account takeover fraud is around
                    $12,000 per case.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={chart} className="mb-4" height={75} />
                  <p>
                    36% of businesses have experienced a security incident
                    caused by the actions of remote workers.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={graph} className="mb-4" height={75} />
                  <p>
                    In the most recent annual report, there has been a rise of
                    over 230% in remote access scams compared to the prior
                    year.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <div className={styles.Contact}>
          <Contact />
        </div>
      </div>
    </AppLayout>
  );
};

export default ContinuousAuthentication;
