import Section from '../Section';
import styles from './Solution.module.scss';

import desktop from './gifs/desktop.gif';
import mobile from './gifs/mobile.gif';
import web from './gifs/web.gif';
import user from './gifs/user.gif';
import device from './gifs/device.gif';
import biometrics from './gifs/biometrics.gif';
import filter from './gifs/filter.gif';
import detection from './gifs/detection.gif';
import profiling from './gifs/profiling.gif';

import logo from './logo.svg';
import arrow from './arrow.svg';
import play from './play.svg';

const Solution = () => (
  <Section
    id="Solution"
    className={styles.Solution}
  >
    <div className="container mt-3 mt-lg-0">
      <div className="row">
        <div className="col-12 text-center mb-3 mb-lg-5">
          <h1>Solution Overview</h1>
        </div>
      </div>
      <div className={`row mb-5 ${styles.LogoItem}`}>
        <div className="col-lg-6 offset-lg-6">
          <img src={logo} width={500} />
        </div>
      </div>
      <div className="row">
        <div className={styles.SolutionItems}>
          <div className={styles.Devices}>
            <div className={styles.SolutionHead}>
              <h3>Secured Platforms</h3>
            </div>
            <div className={styles.SolutionContent}>
              <div className={styles.Item}>
                <div className={styles.ItemImg}>
                  <img src={desktop} width={75} />
                </div>
                <p>Desktop</p>
              </div>
              <div className={styles.Item}>
                <div className={styles.ItemImg}>
                  <img src={mobile} width={75} />
                </div>
                <p>Mobile</p>
              </div>
              <div className={styles.Item}>
                <div className={styles.ItemImg}>
                  <img src={web} width={75} />
                </div>
                <p>Web</p>
              </div>
            </div>
          </div>
          <div className={styles.Graphics}>
            <img src={arrow} width={50} />
          </div>
          <div className={styles.Border}>
            <div className={styles.Data}>
              <div className={styles.SolutionHead}>
                <h3>Analyzed Data</h3>
              </div>
              <div className={styles.SolutionContent}>
                <div className={styles.Item}>
                  <div className={styles.ItemImg}>
                    <img src={user} width={75} />
                  </div>
                  <p>User Interaction</p>
                </div>
                <div className={styles.Item}>
                  <div className={styles.ItemImg}>
                    <img src={device} width={75} />
                  </div>
                  <p>Device Profile</p>
                </div>
                <div className={styles.Item}>
                  <div className={styles.ItemImg}>
                    <img src={biometrics} width={75} />
                  </div>
                  <p>Behavioural Biometrics</p>
                </div>
              </div>
            </div>
            <div className={styles.Graphics}>
              <img src={arrow} width={50} />
            </div>
            <div className={styles.Result}>
              <div className={styles.SolutionHead}>
                <h3>AI Competence</h3>
              </div>
              <div className={styles.SolutionContent}>
                <div className={styles.Item}>
                  <div className={styles.ItemImg}>
                    <img src={filter} width={75} />
                  </div>
                  <p>Captcha & Bot Filter</p>
                </div>
                <div className={styles.Item}>
                  <div className={styles.ItemImg}>
                    <img src={detection} width={75} />
                  </div>
                  <p>Remote Desktop Detection</p>
                </div>
                <div className={styles.Item}>
                  <div className={styles.ItemImg}>
                    <img src={profiling} width={75} />
                  </div>
                  <p>Biometric Profiling</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center mt-5">
          <a href="/case-study" className="btn">
            Learn more
            {' '}
            <img src={play} />
          </a>
        </div>
      </div>
    </div>
  </Section>
);

export default Solution;
